import React, { useRef, useEffect } from 'react';

export default outerRef => {
	let ref = useRef();

	useEffect(() => {
		if (outerRef) outerRef.current = ref.current;
	});

	return ref;
};
