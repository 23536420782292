export function boxShadow(depth) {
	switch (parseInt(depth, 10)) {
		case -1:
			return 'inset 0 -1px 4px rgba(0, 0, 0, 0.37)';
		case 0:
			return 'none';
		case 1:
			return '0 1px 4px rgba(0, 0, 0, 0.37)';
		case 2:
			return '0 2px 2px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.3)';
		case 3:
			return '0 11px 7px rgba(0, 0, 0, 0.19), 0 13px 25px rgba(0, 0, 0, 0.3)';
		case 4:
			return '0 14px 12px rgba(0, 0, 0, 0.17), 0 20px 40px rgba(0, 0, 0, 0.3)';
		case 5:
			return '0 17px 17px rgba(0, 0, 0, 0.15), 0 27px 55px rgba(0, 0, 0, 0.3)';
		default:
			return 'none';
	}
}

export function borderRadius(shape) {
	switch (shape) {
		case 'rect':
		case 'square':
			return '0px';
		case 'round':
		case 'circle':
		case 'ellipse':
			return '50%';
		case 'card':
		case 'rounded':
			return '4px';
		case 'pill':
			return '100vmax';
		default:
			return '0px';
	}
}
