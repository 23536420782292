import React, { forwardRef, useRef, useState, useEffect, useContext, useLayoutEffect } from 'react';
import Style from 'react-style-sheet';
import Color from 'material/src/utils/color';
import Paper from 'material/src/components/paper';
import Label from 'material/src/components/label';
import ClassNames from 'classnames';

import useHover from 'hooks/use-hover';
import useTheme from 'material/src/hooks/use-theme';
import useRipples from 'material/src/hooks/use-ripples';
import useOuterRef from 'hooks/use-outer-ref';

const Stylesheet = Style`
    > * {
		display: grid;
		grid-auto-flow: row;
		align-items: center;
		align-content: center;
		justify-items: start;
		justify-content: start;
		grid-auto-columns: max-content;
		grid-template-columns: max-content 1fr max-content;
		grid-template-areas: "left main right";

        cursor: pointer;
        overflow: hidden;
		position: relative;

		> Label:first-child {
			grid-column-start: main;
		}

        > Label {
			justify-self: stretch;
			pointer-events: none;
		}

		> Label[onClick] {
			pointer-events: auto;
		}

		> Icon, > Button[type=icon], > Label {
			margin: 8px 16px;
		}
	}
	
	> *.hover {
		background-color: ${Color('gray', 200)};
	}

	> *.selected {
		background-color: ${Color('gray', 300)};
	}

	> *.shade-dark.hover {
		background-color: ${Color('gray', 800)};
	}

	> *.shade-dark.selected {
		background-color: ${Color('gray', 700)};
	}
`;

const BasicListitem = forwardRef((props, outerRef) => {
	let { className, label, selected, disabled, children, ...other } = props;

	let ref = useOuterRef(outerRef);
	let hover = useHover(ref);
	let theme = useTheme();
	let ripples = useRipples(ref, { disabled });

	let classNames = ClassNames(className, 'BasicListitem', `shade-${theme.shade}`, { hover, selected });

	let renderLabel;
	if (label != undefined) {
		renderLabel = <Label type="subtitle1" text={label} lines={1} />;
	}

	return (
		<Stylesheet>
			<div ref={ref} className={classNames} depth={0} {...other}>
				{renderLabel}
				{children}
				{ripples}
			</div>
		</Stylesheet>
	);
});

BasicListitem.displayName = 'BasicListitem';
BasicListitem.defaultProps = {};

export default BasicListitem;
