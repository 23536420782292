// @ts-check

import { useContext } from 'react';
import Material from 'material/src/utils/color/scheme/material';
import ThemeContext from 'material/src/utils/theme/context';
import SchemeContext from 'material/src/utils/color/scheme/context';

/**
 *
 * @param {(string|Function|Object)} current
 * @param  {Array<string|number>} lookups
 * @returns {string}
 */
let lookup = (current, ...lookups) => {
	if (typeof current === 'string') {
		return current;
	} else if (typeof current === 'function') {
		let [arg, ...subloookups] = lookups;
		return lookup(current(arg), ...subloookups);
	} else if (typeof current === 'object') {
		let [arg, ...subnames] = lookups;
		return lookup(current[arg], ...subnames);
	}
};

/**
 * @param {(string|number|Function|Object<string, any>)} scheme
 * @param {Array<(string|number)>} args
 * @returns {string}
 */
let useColor = (scheme, ...args) => {
	let Theme = useContext(ThemeContext);
	let Scheme = useContext(SchemeContext);

	if (scheme == undefined) scheme = Scheme;

	args = args.filter(arg => arg != undefined);

	if (typeof scheme === 'string') {
		if (scheme === 'css' || scheme === 'material') {
			if (args[0] === 'primary') {
				args[0] = Theme.primary;
			} else if (args[0] === 'secundary') {
				args[0] = Theme.secundary;
			}
		}

		if (scheme === 'css') {
			return args[0];
		} else if (scheme === 'material') {
			return lookup(Material, args[0], args[1] || 500);
		} else {
			return lookup(Scheme, scheme, ...args);
		}
	}

	return lookup(scheme, ...args);
};

export default useColor;
