import React, { useMemo } from 'react';
import Style from 'react-style-sheet';
import Color from 'material/src/utils/color';
import Classnames from 'classnames';
import ThemeContext from 'material/src/utils/theme/context';
import SchemeContext from 'material/src/utils/color/scheme/context';

import defaultTheme from 'material/src/utils/theme/default';

const Stylesheet = Style`
	> div {
		width: 100%;
		height: 100%;

		font-size: 16px;
		font-weight: 400;
		user-select: text;
		line-height: 1.3em;
		letter-spacing: 0.5px;
		font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

		overflow: auto;
		-webkit-overflow-scrolling: touch;

		background-color: ${Color('gray', 50)}; 
	}

	> div.shade-dark {
		background-color: ${Color('gray', 900)};
	}
`;

function Application(props) {
	let { className, scheme, theme, ...other } = props;

	let applicationTheme = useMemo(() => {
		return { ...defaultTheme, ...theme };
	}, [theme]);

	let classNames = Classnames(className, 'Application', `shade-${applicationTheme.shade}`);

	return (
		<SchemeContext.Provider value={scheme}>
			<ThemeContext.Provider value={applicationTheme}>
				<Stylesheet>
					<div className={classNames} {...other} />
				</Stylesheet>
			</ThemeContext.Provider>
		</SchemeContext.Provider>
	);
}

Application.displayName = 'Application';
Application.defaultProps = {
	theme: {},
	scheme: 'material',
};

export default Application;
