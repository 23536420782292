import React, { forwardRef, useContext } from 'react';
import Style from 'react-style-sheet';
import { useSpring, interpolate, animated, config } from 'react-spring';

import useTheme from 'material/src/hooks/use-theme';
import useColor from 'material/src/hooks/use-color';

const Stylesheet = Style`
    > * {
		left: 0;
        width: 100%;
        height: 2px;
        bottom: 0px;
        z-index: 100;
        position: absolute;
		pointer-events: none;
		background-color: #000;
	}
	
	> *.shade-dark {
		background-color: #FFF;
	}
`;

const Indicator = forwardRef((props, outerRef) => {
	let theme;
	let { className, scheme, color, shade, start, style, ...other } = props;
	theme = useTheme();
	color = useColor(scheme, color, shade);

	let from = { width: '100%', center: '50%' };
	let to = { width: '100%', center: '50%' };

	if (start != undefined) from = start;

	let { width, center } = useSpring({ from, to, config: config.stiff });
	let left = interpolate([width, center], (w, c) => `calc(${c} - ${w} / 2)`);

	style = { ...style, width, left };
	if (color) {
		style.backgroundColor = color;
	}

	let classNames = (className, 'Indicator', `shade-${theme.shade}`);

	return (
		<Stylesheet>
			<animated.div ref={outerRef} className={classNames} style={style} {...other} />
		</Stylesheet>
	);
});

Indicator.displayName = 'Indicator';
Indicator.defaultProps = {};

export default Indicator;
