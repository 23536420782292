import React, { forwardRef, useRef, useState, useEffect, useContext } from 'react';
import Style from 'react-style-sheet';
import Label from 'material/src/components/label';
import ThemeContext from 'material/src/utils/theme/context';

import useValue from 'material/src/hooks/use-value';
import useFocus from 'hooks/use-focus';
import useThemeColor from 'material/src/hooks/use-theme-color';
import useGlobalNumber from 'hooks/use-global-number';

let Stylesheet = Style`
	> div {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		> #container {
			display: flex;	
			flex-direction: column;
			position: relative;
			background-color: rgba(0,0,0,0.05);
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			border-bottom: 1px solid rgba(0,0,0,0.25);

			> #content {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: stretch;
				padding: 4px 8px 5px;

				> * {
					display: flex;
					flex: 0 0 auto;
					padding: 4px;
				}

				> #maincontent {
					display: flex;
					flex: 1 1 auto;
					position: relative;

					> Label {
						position: absolute;
						top: 14px;
						pointer-events: none;
						font-size: 16px;
						transform-origin: left top;
						transition: transform 200ms ease-out;
					}

					> input {
						width: 100%;
						border: none;
						background-color: transparent;
						font-size: 16px;
						font-weight: 400;
						letter-spacing: 0.15px;
						position: relative;
					}
				}
			}

			> #indicator {
				position: absolute;
				width: 100%;
				height: 2px;
				bottom: -1px;
				transition: transform 200ms ease-out;
				transform-origin: center bottom;
			}
		}
	}
`;

const Textfield = forwardRef((props, outerRef) => {
	let ref = useRef();
	let input = useRef();
	let theme = useContext(ThemeContext);
	let scope = useGlobalNumber();
	let focus = useFocus(input);

	let {
		id,
		className,
		type,
		label,
		color,
		value,
		style,
		autoFocus,
		spellCheck,
		autoCorrect,
		autoComplete,
		autoCapitalize,
		disabled,
		tabIndex,
		placeholder,
		decoratorBottom,
		decoratorLeft,
		decoratorRight,
		defaultValue,
		onChange,
		onMouseDown,
		onBlur,
		onFocus,
		...other
	} = props;

	let [inputValue, handleChange] = useValue(props);

	color = useThemeColor(color);

	useEffect(() => {
		if (outerRef) outerRef.current = input.current;
	});

	let handleFocus = event => {
		if (input.current) input.current.focus();
		if (onFocus) onFocus(event);
	};

	useEffect(() => {
		if (autoFocus) input.current.focus();
	}, []);

	let outerStyle = {
		margin: style.margin,
		minWidth: style.minWidth,
		alignSelf: style.alignSelf,
		cursor: disabled ? 'default' : 'text',
	};

	let textfieldStyle = {
		fontSize: style.fontSize,
		textAlign: style.textAlign,
		caretColor: color,
		opacity: focus || (inputValue != undefined && inputValue != '') ? 1 : 0,
	};

	let maincontentStyle = {
		paddingTop: label == undefined ? 4 : 24,
	};

	let labelStyle = {
		color: focus && !disabled ? color : theme.shade == 'dark' ? '#FFF' : '#000',
		opacity: focus && !disabled ? 1 : theme.shade == 'dark' ? 0.7 : 0.42,
		transform:
			focus || (inputValue != undefined && inputValue != '') ? 'scale(0.875) translateY(-12px)' : undefined,
	};

	let indicatorStyle = {
		backgroundColor: color,
		transform: `scale(${focus ? 1 : 0})`,
	};

	let inputProps = {
		ref: input,
		type,
		disabled,
		placeholder,
		spellCheck: spellCheck === true || spellCheck === 'true' ? 'true' : 'false',
		autoCorrect: autoCorrect === true || autoCorrect === 'on' ? 'on' : 'off',
		autoComplete: autoComplete === true || autoComplete === 'on' ? 'on' : 'off',
		autoCapitalize: autoCapitalize === true || autoCapitalize === 'on' ? 'on' : 'off',
		value: inputValue,
		onChange: handleChange,
	};

	let identifier = `material-scope-${scope}`;

	return (
		<Stylesheet {...props}>
			<style>
				{`
					#${identifier} #maincontent input::placeholder {
						color: ${theme.shade == 'dark' ? '#FFF' : '#000'};
						opacity: ${theme.shade == 'dark' ? 0.38 : 0.16};
					}

					#${identifier} #maincontent input::-webkit-inner-spin-button {
						margin: 0;
						-webkit-appearance: none;
					}
				`}
			</style>
			<div ref={ref} id={identifier} tabIndex={tabIndex} onFocus={handleFocus} style={outerStyle} {...other}>
				<div id="container">
					<div id="content">
						{decoratorLeft && <div id="decorator-left">{decoratorLeft}</div>}

						<div id="maincontent" style={maincontentStyle}>
							<Label type="caption" style={labelStyle}>
								{label}
							</Label>
							<input style={textfieldStyle} {...inputProps} />
						</div>

						{decoratorRight && <div id="decorator-right">{decoratorRight}</div>}
					</div>
					<div id="indicator" style={indicatorStyle} />
				</div>
				{decoratorBottom && <div id="decorator-bottom">{decoratorBottom}</div>}
			</div>
		</Stylesheet>
	);
});

Textfield.displayName = 'Textfield';
Textfield.defaultProps = {
	style: {},
	type: 'text',
	color: 'secundary',
	tabIndex: 0,
};

export default Textfield;
