import React, { forwardRef, useRef, useState, useMemo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Style from 'react-style-sheet';
import { useSpring, animated } from 'react-spring';

import Paper from 'material/src/components/paper';
import Button from 'material/src/components/button';

import node from './setup';
import useRendered from 'hooks/use-rendered';
import useForceUpdate from 'hooks/use-force-update';

const Stylesheet = Style`
	> * {
		display: flex;
		flex-direction: column;

		width: 100%;
		height: 100%;

		> Paper {
			display: flex;
			flex: 1 1 auto;
			flex-direction: column;
			position: relative;

			> Button[type=icon][icon=close] {
				top: 0;
				right: 0;
				margin: 16px;
				position: absolute;
			}
		}
	}

	> *.visible {
		opacity: 1;
		pointer-events: auto;
	}
`;

const ModalContainer = forwardRef((props, outerRef) => {
	let { className, showClose, onDismiss, onKeyUp, children, ...other } = props;

	let ref = useRef();
	let update = useForceUpdate();
	let rendered = useRendered();
	let visible = children != undefined && rendered;

	// Allow escape key to dismiss the modal
	useEffect(() => {
		if (visible) {
			let handleKeyUp = event => {
				if (onKeyUp) onKeyUp(event);
				if (!event.defaultPrevented && event.keyCode === 27 && onDismiss) {
					onDismiss(event);
				}
			};

			ref.current.addEventListener('keyup', handleKeyUp);

			return () => {
				ref.current.removeEventListener('keyup', handleKeyUp);
			};
		}
	}, [visible, onKeyUp, onDismiss]);

	// Cache the children for animating the empty modal
	let previousChildren = useRef(children);

	useEffect(() => {
		if (children) previousChildren.current = children;
	}, [children]);

	// Focus the modal when shown
	useEffect(() => {
		if (visible) {
			let focusElement = document.activeElement;
			if (!ref.current.contains(focusElement)) {
				let focusElement = ref.current.querySelector('*[tabIndex]');
				if (focusElement) {
					focusElement.focus();
				} else {
					ref.current.focus();
				}
			}
		}
	}, [visible]);

	className = `ModalContainer ${className} ${visible ? 'visible' : ''}`;

	let render = children == undefined ? previousChildren.current : children;

	let renderCloseButton;
	if (showClose) {
		renderCloseButton = <Button type="icon" icon="close" onClick={onDismiss} />;
	}

	let handleRest = () => {
		if (visible === false && previousChildren.current != undefined) {
			previousChildren.current = undefined;
			update();
		}
	};

	let style = useSpring({ transform: `translateY(${visible ? 0 : 100}%)`, onRest: handleRest });

	let modalcontainer = (
		<Stylesheet>
			<animated.div className={className} style={style}>
				<Paper ref={ref} tabIndex={0}>
					{renderCloseButton}
					{render}
				</Paper>
			</animated.div>
		</Stylesheet>
	);

	return ReactDOM.createPortal(modalcontainer, node);
});

ModalContainer.displayName = 'ModalContainer';
ModalContainer.defaultProps = {
	className: '',
	showClose: true,
};

export default ModalContainer;
