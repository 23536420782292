import React, { forwardRef, useRef } from 'react';
import Style from 'react-style-sheet';
import ClassNames from 'classnames';
import Paper from 'material/src/components/paper';
import Material from 'material/src/utils/color/scheme/material';

import useTheme from 'material/src/hooks/use-theme';
import useColor from 'material/src/hooks/use-color';
import useValue from 'material/src/hooks/use-value';
import useRipples from 'material/src/hooks/use-ripples';

const Stylesheet = Style`
	> div {
		width: 38px;
		display: inline-grid;
		align-items: center;
		justify-items: center;
		pointer-events: auto;

		> [shape=pill] {
			width: 32px;
			height: 14px;
			opacity: 0.38;
			grid-row: 1 / 1;
			grid-column: 1 / 1;
			transition: all 200ms ease-out;
		}

		> [shape=circle] {
			width: 20px;
			height: 20px;
			overflow: visible;
			grid-row: 1 / 1;
			grid-column: 1 / 1;
			transform: translateX(-9px);
			transition: all 200ms ease-out;
		}
	}

	> div.value-true {
		> [shape=pill] {
			opacity: 0.38;
		}

		> [shape=circle] {
			transform: translateX(9px);
		}
	}

	> div.disabled {
		opacity: 0.38;
	}
`;

const Switch = forwardRef((props, outerRef) => {
	let { className, scheme, color, shade, disabled, value, onClick, onTouchStart, onChange, ...other } = props;

	let knob = useRef();
	let theme = useTheme();
	let [switchValue, handleChange] = useValue(props);

	let rippleColor = { scheme, color, shade };
	if (disabled || switchValue) {
		rippleColor = { scheme: 'material', color: 'gray', shade: theme.shade == 'dark' ? 400 : 50 };
	}

	let trackColor = { scheme, color, shade };
	if (!switchValue) {
		trackColor = { scheme: 'css', color: theme.shade == 'dark' ? 'white' : 'black' };
	}

	let knobColor = { scheme, color, shade };
	if (!switchValue) {
		knobColor = { scheme: 'material', color: 'gray', shade: theme.shade == 'dark' ? 400 : 50 };
	}

	let ripples = useRipples(knob, {
		...rippleColor,
		center: true,
		radius: ['50%', '100%'],
		opacity: [0.5, 0],
		disabled,
	});

	let handleClick = event => {
		if (onClick) onClick(event);
		if (!disabled) handleChange(event, !switchValue);
	};

	let classNames = ClassNames('Switch', className, `value-${switchValue}`, { disabled });

	return (
		<Stylesheet>
			<div ref={outerRef} className={classNames} onClick={handleClick} {...other}>
				<Paper shape="pill" depth={0} {...trackColor} />
				<Paper ref={knob} shape="circle" depth={1} {...knobColor}>
					{ripples}
				</Paper>
			</div>
		</Stylesheet>
	);
});

Switch.displayName = 'Switch';
Switch.defaultProps = {
	color: 'secundary',
	shade: 'A400',
	defaultValue: false,
};

export default Switch;
