import React, { useRef, useContext, forwardRef, useEffect } from 'react';
import Style from 'react-style-sheet';
import ThemeContext from 'material/src/utils/theme/context';
import ClassNames from 'classnames';

import icons from './icons';
import useColor from 'material/src/hooks/use-color';

const Stylesheet = Style`
	> svg {
		flex: 0 0 auto;
		width: 24px;
		height: 24px;
		fill: #000;
		opacity: 0.87;
		display: inline-block;
	}

	> svg.shade-dark {
		fill: #FFF;
	}
`;

const Icon = forwardRef((props, outerRef) => {
	let { className, icon, size, scheme, color, shade, children, style, ...other } = props;

	let theme = useContext(ThemeContext);
	let iconcolor = useColor(scheme, color, shade);

	style = { ...style };
	if (style.fill == undefined) {
		if (iconcolor != undefined) {
			style.fill = iconcolor;
		}
	}
	if (style.width == undefined) {
		if (size != undefined) {
			style.width = size;
		}
	}
	if (style.height == undefined) {
		if (size != undefined) {
			style.height = size;
		}
	}

	let classNames = ClassNames(className, 'Icon', `shade-${theme.shade}`);

	return (
		<Stylesheet>
			<svg
				ref={outerRef}
				className={classNames}
				viewBox="0 0 24 24"
				preserveAspectRatio="xMidYMid slice"
				style={style}
				{...other}
			>
				{icons[icon]}
				{children}
			</svg>
		</Stylesheet>
	);
});

Icon.displayName = 'Icon';
Icon.defaultProps = {
	style: {},
};

export default Icon;
