let root;

if (typeof document !== 'undefined') {
	let style;

	root = document.createElement('div');
	root.id = 'dialog';

	style = document.createElement('style');
	style.appendChild(
		document.createTextNode(`
			body > #dialog {
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 2000;
				position: fixed;
				pointer-events: none;
			}
	
			body > #dialog > :not(:last-child) {
				display: none;
			}
		`),
	);

	document.body.appendChild(root);
	document.head.appendChild(style);
}

export default root;
