import React, { useRef, useState, useContext, useEffect, forwardRef } from 'react';
import Style from 'react-style-sheet';
import Icon from 'material/src/components/icon';
import Paper from 'material/src/components/paper';
import Label from 'material/src/components/label';

import useHover from 'hooks/use-hover';
import useFocus from 'hooks/use-focus';
import useRipples from 'material/src/hooks/use-ripples';

const Stylesheet = Style`
	> Paper {
		display: inline-flex;
		flex: 0 0 auto;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		display: inline-block;
		position: relative;
		padding: 5px 8px;
		min-width: 88px;
		cursor: pointer;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		> Icon {
			margin: 0 5px;
		}

		> Label {
			margin: 5px 8px;
			white-space: nowrap;
		}

	}
`;

const FlatButton = forwardRef((props, outerRef) => {
	let opacity, textcolor, background;
	let { className, color, label, icon, disabled, onClick, children, ...other } = props;

	let ref = useRef();
	useEffect(() => {
		if (outerRef) outerRef.current = ref.current;
	});

	let hover = useHover(ref);
	let focus = useFocus(ref);
	let ripples = useRipples(ref, { color, disabled });

	opacity = focus ? (disabled ? 0.05 : 0.1) : hover ? (disabled ? 0 : 0.05) : 0;
	textcolor = disabled ? '#BDBDBD' : color;
	background = disabled ? 'rgba(0, 0, 0, 0.5)' : color;

	if (typeof icon === 'string') icon = <Icon icon={icon} />;
	if (typeof label === 'string') label = <Label type="button" text={label} color={textcolor} />;

	let layer = <div id="background" style={{ opacity, backgroundColor: background }} />;

	function handleClick(event) {
		if (disabled) event.preventDefault(); // Prevents submit buttons from submitting forms when disabled
		if (!disabled && onClick) onClick(event);
	}

	return (
		<Stylesheet {...props}>
			<Paper
				ref={ref}
				className={className}
				element="button"
				depth={0}
				shape="rounded"
				color="transparent"
				tabIndex={0}
				onClick={handleClick}
				{...other}
			>
				{layer}
				{ripples}
				{icon}
				{label}
				{children}
			</Paper>
		</Stylesheet>
	);
});

FlatButton.displayName = 'FlatButton';
FlatButton.defaultProps = {
	className: 'FlatButton',
	style: {},
	color: 'primary',
};

export default FlatButton;
