import React, { forwardRef, useRef, useState, useEffect } from 'react';
import Style from 'react-style-sheet';
import FlatButton from '../flat';

let Stylesheet = Style`
    > * {
        border: 1px solid #BDBDBD;
    }
`;

const OutlineButton = forwardRef((props, outerRef) => {
	return (
		<Stylesheet>
			<FlatButton {...props} />
		</Stylesheet>
	);
});

OutlineButton.displayName = 'OutlineButton';
OutlineButton.defaultProps = {};

export default OutlineButton;
