import React, { forwardRef, useRef, useState, useEffect, useContext } from 'react';
import Style from 'react-style-sheet';
import Icon from 'material/src/components/icon';
import Theme from 'material/src/utils/theme/context';
import ClassNames from 'classnames';

import useHover from 'hooks/use-hover';
import useFocus from 'hooks/use-focus';
import useActive from 'hooks/use-active';
import useRipples from 'material/src/hooks/use-ripples';

const Stylesheet = Style`
	> div {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 auto;
		position: relative;
		overflow: visible;

		> Icon {
			opacity: 0.6;
			pointer-events: none;
		}
	}

	> div.dark {
		> Icon {
			opacity: 0.8;
		}
	}

	> div.focus, div.hover {
		> Icon {
			opacity: 1;
		}
	}

	> div.disabled {
		> Icon {
			opacity: 0.2;
		}
	}
`;

const IconButton = forwardRef((props, outerRef) => {
	let { className, icon, color, disabled, tabIndex, onClick, ...other } = props;

	let ref = useRef();
	let hover = useHover(ref);
	let focus = useFocus(ref);
	let theme = useContext(Theme);
	let ripples = useRipples(ref, { center: true, radius: 24, disabled });
	let tabindex = disabled ? undefined : tabIndex;

	let handleClick = event => {
		if (onClick && !disabled) onClick(event);
	};
	let handleKeyDown = event => {
		if (onClick && event.keyCode === 13) onClick(event);
	};

	let classNames = ClassNames(
		'Button-icon',
		className,
		`shade-${theme.shade}`,
		hover && 'hover',
		focus && 'focus',
		disabled && 'disabled',
	);

	return (
		<Stylesheet {...props}>
			<div
				ref={ref}
				className={classNames}
				tabIndex={tabindex}
				onClick={handleClick}
				onKeyDown={handleKeyDown}
				{...other}
			>
				{ripples}
				<Icon icon={icon} color={color} />
			</div>
		</Stylesheet>
	);
});

IconButton.displayName = 'IconButton';
IconButton.defaultProps = {
	tabIndex: 0,
};

export default IconButton;
