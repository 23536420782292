import React, { forwardRef } from 'react';
import Style from 'react-style-sheet';
import ClassNames from 'classnames';
import Icon from 'material/src/components/icon';
import Paper from 'material/src/components/paper';
// import Label from 'material/src/components/label';

import useValue from 'hooks/use-value';
import useTheme from 'material/src/hooks/use-theme';
import useRipples from 'material/src/hooks/use-ripples';
import useOuterRef from 'hooks/use-outer-ref';

// import styleForegroundColor from './style-foreground-color';
// import styleRippleColor from './style-ripple-color';
// import styleForegroundTransform from './style-foreground-transform';
// import styleBackgroundBorderColor from './style-background-border-color';

let Stylesheet = Style`
	> * {
		display: grid;
		width: 48px;
		height: 48px;
		align-items: center;
		justify-items: center;
		align-content: center;
		justify-content: center;

		> * {
			pointer-events: none;
			grid-area: 1 / 1 / -1 / -1;
			width: 18px;
			height: 18px;
		}

		> div {
			border-width: 2px;
			border-style: solid;
			border-color: transparent;
			border-radius: 4px;
		}

		> Paper {
			transform: scale(1);
			transform-origin: center center;
			transition-property: all;
			transition-duration: 200ms;
			
			display: grid;
			align-items: center;
			justify-items: center;
			align-content: center;
			justify-content: center;
		}
	}

	> *.value-false {
		> div {
			border-color: rgba(0,0,0,0.6);
		}

		> Paper {
			opacity: 0;
			transform: scale(0);
		}
	}

	> *.disabled {
		> div {
			border-color: rgba(0,0,0,0.3);
		}

		> Paper {
			opacity: 0.3;
		}
	}

	> *.disabled.value-true {
		> div {
			border-color: transparent;
		}
	}
`;

const Checkbox = forwardRef((props, outerRef) => {
	let { className, scheme, color, shade, disabled, value, onClick, onChange, ...other } = props;

	let ref = useOuterRef(outerRef);

	let theme = useTheme();
	let [checkboxValue, handleChange] = useValue(props);

	let rippleColor = { scheme, color, shade };
	if (disabled || checkboxValue) {
		rippleColor = { scheme: 'material', color: 'gray', shade: 400 };
	}

	let ripples = useRipples(ref, {
		...rippleColor,
		center: true,
		radius: [9, '70.7%'],
		opacity: [0.5, 0],
		disabled,
	});

	let handleClick = event => {
		if (onClick) onClick(event);
		if (!disabled) handleChange(event, !checkboxValue);
	};

	let classNames = ClassNames('Checkbox', className, `value-${checkboxValue}`, { disabled });

	// let foregroundColor = styleForegroundColor(this.props, theme);
	// <Paper id="Checkbox-Foreground" depth={0} shape="rounded" color={foregroundColor}>

	return (
		<Stylesheet>
			<div ref={ref} className={classNames} onClick={handleClick} {...other}>
				{ripples}
				<div />
				<Paper depth={0} shape="rounded" scheme={scheme} color={color} shade={shade}>
					<Icon icon="check" size={18} />
				</Paper>
			</div>
		</Stylesheet>
	);
});

Checkbox.displayName = 'Checkbox';
Checkbox.defaultProps = {
	color: 'secundary',
	shade: 'A400',
	disabled: false,
	defaultValue: false,
};

export default Checkbox;
