import React from 'react';
import Style from 'react-style-sheet';
import ClassNames from 'classnames';

import Paper from 'material/src/components/paper';

let Stylesheet = Style`
	> Paper {
		display: flex;
		flex: 0 0 auto;
		flex-direction: row;
		align-items: center;
		min-height: 48px;
		box-sizing: border-box;

		> Icon, > Button[type=icon] {
			margin: 8px;
		}

		> Icon:first-child, > Button[type=icon]:first-child {
			margin-left: 16px;
		}

		> Icon:last-child, > Button[type=icon]:last-child {
			margin-right: 16px;
		}
	}
`;

const Toolbar = props => {
	let { className, children, ...other } = props;

	let classNames = ClassNames(className, 'Toolbar');

	return (
		<Stylesheet>
			<Paper className={classNames} {...other}>
				{children}
			</Paper>
		</Stylesheet>
	);
};

Toolbar.displayName = 'Toolbar';
Toolbar.defaultProps = {};

export default Toolbar;
