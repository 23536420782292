import React, { forwardRef, useRef, useMemo, useContext } from 'react';
import Style from 'react-style-sheet';
import Paper from 'material/src/components/paper';

import useValue from 'material/src/hooks/use-value';
import useThemeColor from 'material/src/hooks/use-theme-color';

const Stylesheet = Style`
	> Paper {
		display: flex;
		flex: 0 0 auto;
		flex-direction: row;
		justify-content: center;
		min-height: 48px;
		box-sizing: border-box;
		text-transform: uppercase;
	}
`;

// The indicator is renderer by a tab itself, as it has to be width 100% of the tab
// This allows for resizing of the window and the indicator smoothly adjusting its size
// A common indicatorRef is used and assigned by the tab that renders the indicator
// That way when the selection changes, the new tab can render an indicator starting from
// the position of the element that was previously rendered by another tab.

const Tabs = forwardRef((props, outerRef) => {
	let { value, onChange, scheme, color, shade, colorize, children, ...other } = props;

	let indicatorRef = useRef();
	let [tabValue, onTabChange] = useValue(props);

	let paperColor = {};
	if (colorize) {
		paperColor = { scheme, color, shade };
	}

	children = React.Children.map(children, (child, index) => {
		let { value, disabled, onClick } = child.props;

		let props = { indicatorRef };

		if (tabValue == undefined) {
			if (index === 0) props.selected = true;
		} else {
			if (value == undefined) {
				if (tabValue === index) props.selected = true;
			} else {
				if (tabValue === value) props.selected = true;
			}
		}

		if (!colorize) {
			props.scheme = scheme;
			props.color = color;
			props.shade = shade;
		}
		if (!disabled) {
			props.onClick = function(e) {
				if (onTabChange) onTabChange(e, value != undefined ? value : index);
				if (onClick) onClick(e);
			};
		}

		return React.cloneElement(child, props);
	});

	return (
		<Stylesheet>
			<Paper ref={outerRef} depth={0} {...paperColor} {...other}>
				{children}
			</Paper>
		</Stylesheet>
	);
});

Tabs.displayName = 'Tabs';
Tabs.defaultProps = {
	color: 'primary',
	colorize: true,
};

export default Tabs;
