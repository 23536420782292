import React, { forwardRef, useRef, useState, useEffect, useContext } from 'react';
import Style from 'react-style-sheet';
import Type from 'material/src/utils/type';
import ClassNames from 'classnames';

import useTheme from 'material/src/hooks/use-theme';
import useColor from 'material/src/hooks/use-color';
import useRendered from 'hooks/use-rendered';
import useOuterRef from 'hooks/use-outer-ref';

let Stylesheet = Style`
	> * {
		z-index: 0;
		fill: rgba(0,0,0, 0.6);
		pointer-events: none;
		transition-property: opacity, r;
		transition-timing-function: ease-out; 
	}

	> *.shade-dark {
		fill: rgba(255,255,255, 0.6);
	}
`;

const Ripple = forwardRef((props, outerRef) => {
	let { id, className, x, y, scheme, color, shade, radius, duration, opacity, onExpire, style, ...other } = props;

	let ref = useOuterRef(outerRef);
	let theme = useTheme();
	let expired = useRef(false);
	let rendered = useRendered();
	let rippleColor = useColor(scheme, color, shade);

	if (Type(radius) !== 'Array') radius = [0, radius];
	if (Type(opacity) !== 'Array') opacity = [opacity, 0];

	useEffect(() => {
		let handleExpire = event => {
			if (!expired.current && onExpire) {
				onExpire(event);
			}
			expired.current = true;
		};

		ref.current.addEventListener('transitionend', handleExpire);
		return () => {
			ref.current.removeEventListener('transitionend', handleExpire);
		};
	}, [onExpire]);

	style = { ...style };
	style.opacity = rendered ? opacity[1] : opacity[0];
	style.transitionDuration = duration + 'ms';
	if (rippleColor) style.fill = rippleColor;

	let classNames = ClassNames(className, 'Ripple', `shade-${theme.shade}`);

	return (
		<Stylesheet>
			<circle
				ref={ref}
				className={classNames}
				cx={x}
				cy={y}
				r={rendered ? radius[1] : radius[0]}
				style={style}
				{...other}
			/>
		</Stylesheet>
	);
});

Ripple.displayName = 'Ripple';
Ripple.defaultProps = {
	radius: 50,
	opacity: 1,
	duration: 750,
};

export default Ripple;
