// Or set the prop "type" to one of
// - h1, ..., h6
// - subtitle1
// - subtitle2
// - body1
// - body2
// - button
// - caption
// - overline

import React, { forwardRef, useContext } from 'react';
import Style from 'react-style-sheet';
import Classnames from 'classnames';
import ThemeContext from 'material/src/utils/theme/context';

import useColor from 'material/src/hooks/use-color';

const Stylesheet = Style`
	> * { 
		flex: 0 0 auto;
		color: #000;
		margin: 1em 16px;
		display: inline-block;
		overflow: hidden;
		user-select: none;
		line-height: 1.2em;
		text-overflow: ellipsis;
		text-rendering: optimizeLegibility;
		-webkit-user-select: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-tap-highlight-color: transparent;

		&.shade-dark {
			color: #FFF;
		}
	}

	> h1 {
		font-size: 96px;
		font-weight: 300;
		letter-spacing: -1.5px;
	}

	> h2 {
		font-size: 60px;
		font-weight: 300;
		letter-spacing: -0.5px;
	}

	> h3 {
		font-size: 48px;
		font-weight: 400;
		letter-spacing: 0px;
	}
	> h4 {
		font-size: 34px;
		font-weight: 400;
		letter-spacing: 0.25px;
	}

	> h5 {
		font-size: 24px;
		font-weight: 400;
		letter-spacing: 0px;
	}

	> h6 {
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 0.15px;
	}

	> div.type-subtitle1 {
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.15px;
	}

	> div.type-subtitle2 {
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0.1px;
	}

	> div.type-body1 {
		font-size: 16px;
		font-weight: 400;
		user-select: text;
		letter-spacing: 0.5px;
	}

	> div.type-body2 {
		font-size: 14px;
		font-weight: 400;
		user-select: text;
		letter-spacing: 0.25px;
	}

	> div.type-button {
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 1.25px;
		text-transform: uppercase;
	}

	> div.type-caption {
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0.4px;
	}

	> div.type-overline {
		font-size: 10px;
		font-weight: 400;
		letter-spacing: 1.5px;
		text-transform: uppercase;
	}
`;

const Label = forwardRef((props, outerRef) => {
	let { id, className, type, text, typography, scheme, color, shade, lines, style, children, ...other } = props;

	let Element = 'div';
	if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(type)) {
		Element = type;
	}

	let theme = useContext(ThemeContext);

	color = useColor(scheme, color, shade);

	let maxHeight = props.lines && `${parseInt(props.lines, 10) * 1.2}em`;
	let whiteSpace = props.lines == undefined || props.lines !== 1 ? 'normal' : 'nowrap';

	style = {
		color,
		maxHeight,
		whiteSpace,
		...style,
	};

	let classNames = Classnames(className, 'Label', `shade-${theme.shade}`, `type-${type}`);

	return (
		<Stylesheet>
			<Element ref={outerRef} className={classNames} style={style} {...other}>
				{text}
				{children}
			</Element>
		</Stylesheet>
	);
});

Label.displayName = 'Label';
Label.defaultProps = {
	element: 'div',
	type: 'body1',
};

export default Label;
