import React, { forwardRef } from 'react';
import Style from 'react-style-sheet';
import ClassNames from 'classnames';

import Icon from 'material/src/components/icon';
import Paper from 'material/src/components/paper';
import Label from 'material/src/components/label';

import useOuterRef from 'hooks/use-outer-ref';

const Stylesheet = Style`
	> Paper {
		display: grid;
		align-content: center;
		justify-content: center;
		width: 40px;
		height: 40px;

		> * {
			grid-row: 1;
			grid-column: 1;
			margin: 0;
		}
	}
`;

const Avatar = forwardRef((props, outerRef) => {
	let { className, children, icon, label, ...other } = props;

	let ref = useOuterRef(outerRef);

	if (typeof icon === 'string') {
		icon = <Icon icon={icon} />;
	}

	if (typeof label === 'string' || typeof label === 'number') {
		label = <Label type="h6" text={label} />;
	}

	let classNames = ClassNames(className, 'Avatar');

	return (
		<Stylesheet>
			<Paper ref={ref} className={classNames} shape="round" depth={0} {...other}>
				{icon}
				{label}
				{children}
			</Paper>
		</Stylesheet>
	);
});

Avatar.displayName = 'Avatar';
Avatar.defaultProps = {};

export default Avatar;
