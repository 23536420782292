import React, { useRef, useState, useEffect, useMemo } from 'react';
import Style from 'react-style-sheet';
import { useTransition, animated, config } from 'react-spring';

import Icon from 'material/src/components/icon';
import Label from 'material/src/components/label';

import useRipples from 'material/src/hooks/use-ripples';

const Stylesheet = Style`
	> div {
		display: flex;
		flex-direction: column;
		flex: 1 0 0;
		align-items: center;
		justify-content: center;
		position: relative;
		overflow: hidden;

		min-width: 80px;
		max-width: 168px;
		min-height: 56px;
	}
`;

function NavigationItem(props) {
	let ref = useRef();

	let { value, icon, label, color, selected, disabled, ...other } = props;

	let ripples = useRipples(ref, { disabled, color });

	if (!selected) {
		color = undefined;
	}

	let opacity = selected ? 1 : 0.4;

	if (typeof icon === 'string') {
		icon = <Icon icon={icon} color={color} style={{ opacity }} />;
	}

	if (typeof label === 'string') {
		label = <Label text={label} color={color} type="caption" style={{ opacity }} />;
	}

	let showLabel = label && selected;
	let transition = useTransition(showLabel, undefined, {
		from: { opacity: 0, height: 0 },
		enter: { opacity: 1, height: 16 },
		leave: { opacity: 0, height: 0 },
		config: { ...config.stiff, clamp: true },
	});

	// let renderLabel = transition.map(({ item, key, props }) => {
	// 	return (
	// 		item && (
	// 			<animated.div key={key} style={props}>
	// 				{label}
	// 			</animated.div>
	// 		)
	// 	);
	// });

	return (
		<Stylesheet>
			<div ref={ref} {...other}>
				{ripples}
				{icon}
				{/* {renderLabel} */}
			</div>
		</Stylesheet>
	);
}

NavigationItem.displayName = 'NavigationItem';
NavigationItem.defaultProps = {
	disabled: false,
	selected: false,
};

export default NavigationItem;
