let root;

if (typeof document !== 'undefined') {
	let style;
	root = document.createElement('div');
	root.id = 'modal';

	style = document.createElement('style');
	style.appendChild(
		document.createTextNode(`
		body > #modal {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1000;
			position: fixed;
			pointer-events: none;
		}

		body > #modal > :not(:last-child) {
			display: none;
		}
	`),
	);

	document.body.appendChild(root);
	document.head.appendChild(style);
}

export default root;
