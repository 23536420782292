import React, { useRef, useState, useEffect, useMemo, useContext } from 'react';
import Style from 'react-style-sheet';
import ClassNames from 'classnames';

import Icon from 'material/src/components/icon';
import Label from 'material/src/components/label';
import Indicator from './indicator';

import useTheme from 'material/src/hooks/use-theme';
import useRipples from 'material/src/hooks/use-ripples';

const Stylesheet = Style`
	> div {
		position: relative;
		flex: 1 1 auto;
		min-width: 100px;
		max-width: 256px;
		cursor: pointer;
		overflow: visible;

		> div {
			width: 100%;
			height: 100%;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 8px 12px;
			pointer-events: none;

			> Icon {
				margin: 2px;
			}
	
			> Label {
				margin: 2px;
				text-transform: uppercase;
				letter-spacing: 0;
			}
		}
	}
`;

function Tab(props) {
	let { className, scheme, color, shade, icon, label, disabled, selected, value, indicatorRef, ...other } = props;

	let ref = useRef();
	let theme = useTheme();
	let ripples = useRipples(ref, { disabled, color });

	if (!selected) {
		scheme = undefined;
		color = undefined;
		shade = undefined;
	}

	let opacity = selected ? 1 : theme.shade == 'dark' ? 0.54 : 0.26;

	if (typeof icon === 'string') {
		icon = <Icon icon={icon} color={color} style={{ opacity }} />;
	}

	if (typeof label === 'string') {
		label = <Label text={label} color={color} type="button" style={{ opacity }} />;
	}

	let indicator;
	if (selected) {
		if (indicatorRef.current != undefined) {
			let tabRect = ref.current.getBoundingClientRect();
			let indicatorRect = indicatorRef.current.getBoundingClientRect();
			let indicatorCenter = indicatorRect.x + indicatorRect.width / 2;

			let start = {
				width: `${(indicatorRect.width / tabRect.width) * 100}%`,
				center: `${((indicatorCenter - tabRect.x) / tabRect.width) * 100}%`,
			};

			indicator = <Indicator ref={indicatorRef} color={color} start={start} />;
		} else {
			indicator = <Indicator ref={indicatorRef} color={color} />;
		}
	}

	let classNames = ClassNames(className, 'Tab');

	return (
		<Stylesheet>
			<div ref={ref} className={classNames} {...other}>
				<div>
					{ripples}
					{icon}
					{label}
				</div>
				{indicator}
			</div>
		</Stylesheet>
	);
}

Tab.displayName = 'Tab';
Tab.defaultProps = {};

export default Tab;
