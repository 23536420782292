import { useRef, useState, useEffect } from 'react';

let triggers = ['Space', 'Enter'];

export default ref => {
	let code = useRef();
	let [active, setActive] = useState();

	useEffect(() => {
		if (ref.current == undefined) return;

		let handleMouseDown = () => setActive(true);
		let handleKeyDown = () => {
			if (triggers.includes(event.code)) {
				code.current = event.code;
				setActive(true);
			}
		};

		ref.current.addEventListener('keydown', handleKeyDown);
		ref.current.addEventListener('mousedown', handleMouseDown);
		return () => {
			ref.current.removeEventListener('keydown', handleKeyDown);
			ref.current.removeEventListener('mousedown', handleMouseDown);
		};
	}, [ref]);

	useEffect(() => {
		if (ref.current == undefined) return;

		if (active) {
			let handleMouseUp = () => setActive(false);
			let handleKeyUp = () => {
				if (triggers.includes(event.code)) {
					code.current = undefined;
					setActive(false);
				}
			};

			document.addEventListener('keyup', handleKeyUp);
			document.addEventListener('mouseup', handleMouseUp);
			return () => {
				document.removeEventListener('keyup', handleKeyUp);
				document.removeEventListener('mouseup', handleMouseUp);
			};
		}
	}, [active, ref]);

	return active;
};
