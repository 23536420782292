import React, { forwardRef } from 'react';
import Style from 'react-style-sheet';
import Paper from 'material/src/components/paper';

import useValue from 'material/src/hooks/use-value';
import useThemeColor from 'material/src/hooks/use-theme-color';

const Stylesheet = Style`
	> Paper {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		justify-content: center;
		position: relative;
		box-sizing: content-box;

		min-height: 56px;
	}
`;

const Navigation = forwardRef((props, outerRef) => {
	let { value, onChange, color, colorize, labels, children, ...other } = props;

	let [navigationValue, onNavigationChange] = useValue(props);

	color = useThemeColor(color);
	children = React.Children.map(children, (child, index) => {
		let { value, disabled, onClick } = child.props;

		let props = {};

		if (navigationValue == undefined) {
			if (index === 0) props.selected = true;
		} else {
			if (value == undefined) {
				if (navigationValue === index) props.selected = true;
			} else {
				if (navigationValue === value) props.selected = true;
			}
		}

		if (!labels && props.selected !== true) {
			props.label = null;
		}

		if (!colorize) {
			props.color = color;
		}

		if (!disabled) {
			props.onClick = function(e) {
				if (onNavigationChange) onNavigationChange(e, value != undefined ? value : index);
				if (onClick) onClick(e);
			};
		}

		return React.cloneElement(child, props);
	});

	return (
		<Stylesheet>
			<Paper ref={outerRef} color={colorize ? color : undefined} {...other}>
				{children}
			</Paper>
		</Stylesheet>
	);
});

Navigation.displayName = 'Navigation';
Navigation.defaultProps = {
	className: 'Navigation',
	style: {},
	color: 'primary',
	labels: false,
	colorize: true,
};

export default Navigation;
