import cssToRgba from './css-to-rgba';

export default function(css) {
	if (css == null) return null;

	let rgba = cssToRgba(css);
	if (rgba == null) {
		return null;
	} else if (rgba.a <= 0.25) {
		return null;
	} else {
		let brightness = Math.sqrt(0.299 * rgba.r ** 2 + 0.587 * rgba.g ** 2 + 0.114 * rgba.b ** 2);
		// let lightness = Math.sqrt(0.241 * rgba.r ** 2 + 0.675 * rgba.g ** 2 + 0.068 * rgba.b ** 2);
		return brightness < 150 ? 'dark' : 'light';
	}
}
