import React, { forwardRef, useRef, useMemo, useEffect, useContext } from 'react';
import Style from 'react-style-sheet';
import Color from 'material/src/utils/color';
import Classnames from 'classnames';
import ThemeContext from 'material/src/utils/theme/context';

import useColor from 'material/src/hooks/use-color';
import brightness from 'material/src/utils/color/brightness';
import { boxShadow, borderRadius } from './style';

const Stylesheet = Style`
	> * {
		color: #000;
		overflow: hidden;
		position: relative;
		border-width: 0;
		border-style: solid;
		border-color: ${Color('gray', 200)};
		background-color: #FFF;
	}

	> *.shade-dark {
		color: #FFF;
		border-color: ${Color('gray', 800)};
		background-color: ${Color('gray', 800)};
	}
`;

const Paper = forwardRef((props, outerRef) => {
	let { className, element: Element, scheme, color, shade, depth, shape, style, children, ...other } = props;

	let theme = useContext(ThemeContext);
	let papercolor = useColor(scheme, color, shade);

	papercolor = style.backgroundColor || papercolor;

	let paperTheme = useMemo(() => {
		if (papercolor == undefined) {
			return theme;
		} else {
			return { ...theme, shade: brightness(papercolor) };
		}
	});

	style = { ...style };
	style.zIndex = style.zIndex != undefined ? style.zIndex : Math.max(depth, 0);
	style.boxShadow = style.boxShadow || boxShadow(depth);
	style.borderColor = style.borderColor != undefined ? style.borderColor : papercolor;
	style.borderRadius = borderRadius(shape);
	style.backgroundColor = papercolor;

	let classNames = Classnames(className, 'Paper', `shade-${paperTheme.shade}`);

	return (
		// Not providing a theme provider in case the value doesn't change
		// does not make the react reconciler happy, as it disregards the entire subtree
		// because the shape in this render method changes
		<ThemeContext.Provider value={paperTheme}>
			<Stylesheet>
				<Element ref={outerRef} className={classNames} style={style} {...other}>
					{children}
				</Element>
			</Stylesheet>
		</ThemeContext.Provider>
	);
});

Paper.displayName = 'Paper';
Paper.defaultProps = {
	depth: 1,
	style: {},
	shape: 'rect',
	element: 'div',
};

export default Paper;
