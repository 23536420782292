import React, { forwardRef, useRef, useState, useEffect } from 'react';
import Style from 'react-style-sheet';

import BasicList from '../basic';
import ActionButton from 'material/src/components/button/action';

const Stylesheet = Style`
	> div {
		display: flex;
		flex: 1 1 auto;
		position: relative;

		> BasicList {
			padding-bottom: 48px;
		}
	}
`;

const ActionList = forwardRef((props, outerRef) => {
	let { className, icon, dense, divider, onAction, children, ...other } = props;

	let ref = useRef();
	useEffect(() => {
		if (outerRef) outerRef.current = ref.current;
	});

	return (
		<Stylesheet>
			<div ref={outerRef} className={className} {...other}>
				<ActionButton float={true} icon={icon} onClick={onAction} />
				<BasicList dense={dense} divider={divider}>
					{children}
				</BasicList>
			</div>
		</Stylesheet>
	);
});

ActionList.displayName = 'ActionList';
ActionList.defaultProps = {
	className: 'ActionList',
	style: {},
	icon: 'add',
	dense: false,
	divider: false,
};

export default ActionList;
