import React, { forwardRef, useRef, useEffect } from 'react';
import Style from 'react-style-sheet';
import ClassNames from 'classnames';
import Divider from 'material/src/components/divider';

// Intrinsic height needed for safari, to correctly display the grid listitems. So setting it to 0.

const Stylesheet = Style`
	> div {
		display: grid;
		grid-auto-rows: minmax(48px, max-content);
		grid-template-columns: 1fr;
		align-items: stretch;
		justify-content: stretch;

		padding-top: 12px;
		padding-bottom: 12px;
	}

	> div.dense {
		grid-template-rows: minmax(42px, auto);
		padding-top: 8px;
		padding-bottom: 8px;
	}
`;

const BasicList = forwardRef((props, outerRef) => {
	let { className, dense, divider, children, ...other } = props;

	if (divider === true) divider = <Divider />;

	// if (children != undefined) {
	// 	children = React.Children.toArray(children);
	// 	children = children.map(child => {
	// 		React.cloneElement(child, { dense });
	// 	});

	// if (divider != undefined) {
	// 	children = children;
	// }
	// }
	// if (divider != undefined && children != undefined) {
	// 	children = React.Children.toArray(children).reduce((acc, child) => [...acc, child, divider], []);
	// }

	let classNames = ClassNames(className, 'List', { dense });

	return (
		<Stylesheet>
			<div ref={outerRef} className={classNames} {...other}>
				{children}
			</div>
		</Stylesheet>
	);
});

BasicList.displayName = 'BasicList';
BasicList.defaultProps = {
	divider: false,
};

export default BasicList;
