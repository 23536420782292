import Material from './scheme/material.js';

export default function(color, description = 500) {
	let palette = Material[color];
	if (palette == null) {
		return null;
	} else {
		return palette[description];
	}
}
