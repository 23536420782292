import React, { forwardRef, useRef, useState, useEffect } from 'react';

import BasicListitem from './basic';
import LabelListitem from './label';

const Listitem = forwardRef((props, outerRef) => {
	let { type, ...other } = props;

	let Element;
	if (type === 'label') {
		Element = LabelListitem;
	} else {
		Element = BasicListitem;
	}

	return <Element ref={outerRef} {...other} />;
});

Listitem.displayName = 'Listitem';
Listitem.defaultProps = {};

export default Listitem;
