import { useState, useEffect } from 'react';

export default ref => {
	let [hover, setHover] = useState(false);

	useEffect(() => {
		if (ref.current == undefined) return;

		// The setTimeout is used prevent the double tap issue on iOS
		// https://github.com/facebook/react/issues/14535
		let handleMouseEnter = () => setTimeout(() => setHover(true));
		let handleMouseLeave = () => setTimeout(() => setHover(false));
		ref.current.addEventListener('mouseenter', handleMouseEnter);
		ref.current.addEventListener('mouseleave', handleMouseLeave);
		return () => {
			ref.current.removeEventListener('mouseenter', handleMouseEnter);
			ref.current.removeEventListener('mouseleave', handleMouseLeave);
		};
	}, [ref]);

	return hover;
};
