import React, { useState, useMemo, useEffect } from 'react'
import Style from 'react-style-sheet'
import { Button, Sidebar, List, Listitem, Label, Switch, Checkbox } from 'material'

import database from '/src/database.json'
import sounds from '/src/sounds/*.mp3'

import groupBy from '/src/util/group-by'

const Stylesheet = Style`
	> * {
		position: relative;

		> Sidebar {
			> List {
				> Listitem {
					> Switch {
						margin: 16px;
					}
				}
			}
		}

		> Button#filter {
			top: 0;
			right: 0;
			margin: 16px;
			position: absolute;
			z-index: 100;
		}

		> #solutions {
			width: 100%;
			height: 100%;
			overflow-y: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 32px;

			.characters {
				font-size: 64px;
			}

			.info {
				font-size: 32px;
			}

			> * {
				text-align: center;
			}

			.tone1 {
				color: #FFEB3B;
			}
	
			.tone2 {
				color: #2196F3;
			}
	
			.tone3 {
				color: #4CAF50;
			}
	
			.tone4 {
				color: #F44336;
			}
		}
	}
`

const Exercises = props => {
	let { sound, ...other } = props

	let [tags, setTags] = useState([])
	let cards = useMemo(() => {
		if (tags == undefined || tags.length === 0) {
			return Object.values(database.cards.byId)
		} else {
			let ids = []
			let cards = []
			for (let tag of tags) {
				for (let id of database.cards.byTags[tag]) {
					if (ids.includes(id)) continue

					ids.push(id)
					cards.push(database.cards.byId[id])
				}
			}
			return cards
		}
	}, [tags])

	let calculateSolutions = useMemo(
		() => card => {
			if (card) {
				let ids = database.cards.byFilename[card.filename]
				let alternatives = ids.map(id => database.cards.byId[id])
				let filtered = alternatives.filter(c => cards.includes(c))
				let similar = card => alt =>
					card.hanzi === alt.hanzi && card.translation === alt.translation && card.extra === alt.extra

				let unique = filtered.filter((c, i, a) => a.findIndex(similar(c)) === i)
				return groupBy(unique, card => card.hanzi)
			}
		},
		[cards]
	)

	let [state, setState] = useState(() => {
		let index = Math.floor(Math.random() * cards.length)
		let card = cards[index]
		return { card, solutions: { current: calculateSolutions(card) } }
	})

	let renderSolutions = useMemo(() => {
		let solutions = state.solutions && state.solutions.previous
		if (solutions) {
			return Object.keys(solutions).map(hanzi => {
				let cards = solutions[hanzi]
				let card = cards[0]
				let info = cards
				info = info.reduce((acc, c) => [c.translation, ...acc, c.extra], [])
				info = info.filter(i => i)
				info = info.filter((item, index, array) => array.indexOf(item) === index)

				let renderChars = card.chars.map((c, i) => (
					<span key={i} className={`tone${card.tones[i]}`}>
						{c}
					</span>
				))

				let renderInfo = info.map(i => <div key={i}>{i}</div>)

				return (
					<div key={hanzi}>
						<div className="characters">{renderChars}</div>
						<div className="info">{renderInfo}</div>
					</div>
				)
			})
		}
	}, [state.solutions && state.solutions.previous])

	let [showSidebar, setShowSidebar] = useState(false)
	let handleFilterButtonClick = () => {
		setShowSidebar(true)
	}

	let handleSidebarDismiss = () => {
		setShowSidebar(false)
	}

	let renderTagList = useMemo(() => {
		let sortedTags = Object.values(database.tags.byId).sort((a, b) => {
			if (a.name < b.name) return -1
			if (a.name > b.name) return 1
			return 0
		})
		let tagListitems = sortedTags.map(tag => {
			let selected = tags.includes(tag.id)
			let handleTagListitemClick = () => {
				if (selected) {
					setTags(tags.filter(t => t !== tag.id))
				} else {
					setTags([...tags, tag.id])
				}
			}

			return (
				<Listitem key={tag.id} onClick={handleTagListitemClick}>
					<Checkbox value={selected} onChange={handleTagListitemClick} />
					<Label>{tag.name}</Label>
				</Listitem>
			)
		})
		return (
			<React.Fragment>
				<Label type="h6" text="Filter" />
				<List>{tagListitems}</List>
			</React.Fragment>
		)
	}, [tags, database.tags])

	useEffect(() => {
		sound.currentTime = 0
		sound.src = sounds[state.card.filename]
		sound.play()
	}, [state.card && state.card.filename])

	useEffect(() => {
		let strokes = Object.keys(state.solutions.current).reduce((acc, hanzi) => {
			return acc + state.solutions.current[hanzi][0].strokes
		}, 0)

		let time = (strokes / 3) * 1000 + 4000
		let timeout = setTimeout(() => {
			let index = Math.floor(Math.random() * cards.length)
			let card = cards[index]
			let solutions = {
				previous: state.solutions.current,
				current: calculateSolutions(card),
			}
			setState({ card, solutions })
		}, time)

		return () => {
			clearTimeout(timeout)
		}
	}, [state, cards])

	return (
		<Stylesheet>
			<div {...other}>
				<Sidebar visible={showSidebar} onDismiss={handleSidebarDismiss}>
					{renderTagList}
				</Sidebar>

				<Button id="filter" type="icon" icon="funnel" onClick={handleFilterButtonClick} />
				<div id="solutions">{renderSolutions}</div>
			</div>
		</Stylesheet>
	)
}

Exercises.displayName = 'Exercises'
Exercises.defaultProps = {}

export default Exercises
