import React, { useState, useRef } from 'react'
import Style from 'react-style-sheet'
import { Application, Checkbox } from 'material'

import Splash from '/src/components/splash'
import Exercises from '/src/components/exercises'

const Stylesheet = Style`
    > Application {
        > * {
            width: 100%;
            height: 100%;
        }
	}
	
`

const sound = new Audio()

const App = props => {
	let [splash, setSplash] = useState(true)

	let handleStart = event => {
		setSplash(false)
		sound.play()
	}

	let render
	if (splash) {
		render = <Splash onStart={handleStart} />
	} else {
		render = <Exercises sound={sound} />
	}

	return (
		<Stylesheet>
			<Application>{render}</Application>
		</Stylesheet>
	)
}

export default App
