import React, { forwardRef, useState, useRef, useEffect } from 'react';
import Style from 'react-style-sheet';
import Paper from 'material/src/components/paper';
import ClassNames from 'classnames';
import { useSpring, animated } from 'react-spring';

let Stylesheet = Style`
	> div {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 500;
		overflow: hidden;
		position: absolute;

		> #Sidebar_backdrop {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 1;
			position: absolute;
			transition: opacity 200ms ease-out;
			background-color: rgba(0,0,0,0.26);
		}
	
		> #Sidebar_container {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			max-width: 80%;

			> Paper {
				display: flex;
				flex-direction: column;
				flex: 1 1 auto;
				overflow-y: auto;
				width: 300px;
			}
		}
	}

	> div.hidden {
		pointer-events: none;

		> #Sidebar_backdrop {
			opacity: 0;
		}
	}
`;

const Sidebar = forwardRef((props, outerRef) => {
	let { className, visible, onDismiss, children, ...other } = props;

	useEffect(() => {
		if (visible !== false && onDismiss) {
			let handleKeyDown = e => {
				if (e.keyCode === 27) onDismiss(e);
			};

			document.addEventListener('keydown', handleKeyDown);

			return () => {
				document.removeEventListener('keydown', handleKeyDown);
			};
		}
	}, [visible, onDismiss]);

	let from = { opacity: 0, transform: 'translateX(-100%)' };

	let to;
	if (visible) {
		to = async next => {
			await next({ opacity: 1, immediate: true });
			await next({ transform: 'translateX(0)', immediate: false });
		};
	} else {
		to = async next => {
			await next({ transform: 'translateX(-100%)', immediate: false });
			await next({ opacity: 0, immediate: false });
		};
	}

	let style = useSpring({ from, to, config: { clamp: true } });

	let classNames = ClassNames('Sidebar', className, { hidden: !visible });

	return (
		<Stylesheet>
			<div className={classNames}>
				<div id="Sidebar_backdrop" onClick={onDismiss} />
				<animated.div id="Sidebar_container" style={style}>
					<Paper {...other}>{children}</Paper>
				</animated.div>
			</div>
		</Stylesheet>
	);
});

Sidebar.displayName = 'Sidebar';
Sidebar.defaultProps = {
	visible: true,
};

export default Sidebar;
