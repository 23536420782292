import React, { forwardRef, useContext } from 'react';
import Style from 'react-style-sheet';
import Classnames from 'classnames';
import { useSpring, animated } from 'react-spring';

import useTheme from 'material/src/hooks/use-theme';
import useColor from 'material/src/hooks/use-color';

const Stylesheet = Style`
	> * {
		flex: 0 0 auto;
		display: inline-block;
		opacity: 0.12;
		transform-origin: center center;


		> * {
			fill: none;
			stroke: #000;

			stroke-linecap: butt;
			transform-origin: center center;
		}
	}

	> .dark {
		opacity: 0.26;
		
		> * {
			stroke: #FFF;
			
		}
	}
`;

const CircularProgress = forwardRef((props, outerRef) => {
	return <div style={{ margin: 16 }}>loading...</div>;
	let { id, className, scheme, color, shade, size, thickness, style, ...other } = props;

	let theme = useTheme();
	let progressColor = useColor(scheme, color, shade);

	if (size === 'small') {
		size = 16;
	} else if (size === 'normal') {
		size = 32;
	} else if (size === 'large') {
		size = 48;
	}

	if (thickness == undefined) thickness = size / 8;

	let center = size / 2;
	let radius = (size - thickness) / 2;
	let circumference = 2 * radius * Math.PI;
	let length = circumference * 0.75 + thickness;

	let outerspring = useSpring({
		from: { transform: 'rotate(0deg)' },
		to: async next => {
			while (1) {
				await next({ transform: 'rotate(720deg)' });
				await next({ transform: 'rotate(0deg)' });
			}
		},
	});

	// let innerspring = useSpring({
	// 	from: { time: 0 },
	// 	to: async next => {
	// 		while (true) {
	// 			await next({ time: 1 });
	// 			await next({ time: 0 });
	// 		}
	// 	},
	// 	config: { duration: 1400 },
	// });

	// let innerstyle = {
	// 	stroke: progressColor,
	// 	transform: innerspring.time.interpolate({
	// 		output: ['rotate(-90deg)', 'rotate(0deg)'],
	// 	}),
	// 	strokeDashoffset: innerspring.time.interpolate({
	// 		range: [0, 0.5, 0.9, 1],
	// 		output: [-thickness, -thickness, -length, -length],
	// 	}),
	// 	strokeDasharray: innerspring.time.interpolate({
	// 		range: [0, 0.4, 0.5, 0.9, 1],
	// 		output: [
	// 			`${thickness},${circumference}`,
	// 			`${length},${circumference}`,
	// 			`${length},${circumference}`,
	// 			`${thickness},${circumference}`,
	// 			`${thickness},${circumference}`,
	// 		],
	// 	}),
	// };

	let innerstyle = {
		stroke: progressColor,
	};

	let outerstyle = {
		...style,
		...outerspring,
		width: size,
		height: size,
	};

	let classNames = Classnames(className, 'CircularProgress', `shade-${theme.shade}`);

	return (
		<Stylesheet>
			<animated.svg ref={outerRef} className={classNames} style={outerstyle}>
				<animated.circle cx={center} cy={center} r={radius} strokeWidth={thickness} style={innerstyle} />
			</animated.svg>
		</Stylesheet>
	);
});

CircularProgress.displayName = 'CircularProgress';
CircularProgress.defaultProps = {
	size: 32,
	color: 'secundary',
};

export default CircularProgress;
