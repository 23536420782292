import { useEffect, useState } from 'react';

// onBlur does not work as it should in react, so when rendering a native element (eg. div)
// extract the discard the onBlur & onFocus handlers, and use the native events with this hook
// (see https://github.com/facebook/react/issues/9142)

export default function(ref) {
	let [focus, setFocus] = useState(false);

	useEffect(() => {
		let node = ref.current;
		if (node) {
			let handleBlur = () => setFocus(false);
			let handleFocus = () => setFocus(true);
			node.addEventListener('blur', handleBlur);
			node.addEventListener('focus', handleFocus);
			return () => {
				node.removeEventListener('blur', handleBlur);
				node.removeEventListener('focus', handleFocus);
			};
		}
	}, [ref]);

	return focus;
}
