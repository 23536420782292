import React, { forwardRef, useRef, useState, useEffect } from 'react';
import Style from 'react-style-sheet';
import ReactDOM from 'react-dom';
import { useSpring, animated } from 'react-spring';

import node from './setup';
import useRendered from 'hooks/use-rendered';
import useForceUpdate from 'hooks/use-force-update';

const Stylesheet = Style`
	> .DialogContainer {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		display: flex;
		position: fixed;
		align-items: center;
		justify-content: center;
		pointer-events: none;
		transition: opacity 200ms ease-in-out;

		> #DialogContainer_backdrop {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			position: absolute;
			background-color: rgba(0,0,0,0.5);
		}

		> #DialogContainer_animation {
			max-width: 80%;
			max-height: 80%;
			min-width: 240px;
			box-sizing: border-box;
		}
	}

	> .DialogContainer.visible {
		opacity: 1;
		pointer-events: auto;
	}
`;

const DialogContainer = forwardRef((props, outerRef) => {
	let { className, onDismiss, onKeyUp, children, ...other } = props;

	let ref = useRef();
	let update = useForceUpdate();
	let rendered = useRendered();
	let visible = children != undefined && rendered;

	// Allow escape key to dismiss the dialog
	useEffect(() => {
		if (visible) {
			let handleKeyUp = e => {
				if (onKeyUp) onKeyUp(e);
				if (e.keyCode === 27 && onDismiss) onDismiss();
			};

			ref.current.addEventListener('keyup', handleKeyUp);

			return () => {
				ref.current.removeEventListener('keyup', handleKeyUp);
			};
		}
	}, [visible, onKeyUp, onDismiss]);

	// Cache the children for animating the empty dialog
	let previousChildren = useRef(children);

	useEffect(() => {
		if (children) previousChildren.current = children;
	}, [children]);

	// Focus the dialog when shown
	useEffect(() => {
		if (visible) {
			let focusElement = document.activeElement;
			if (!ref.current.contains(focusElement)) {
				let focusElement = ref.current.querySelector('*[tabIndex]');
				if (focusElement) {
					focusElement.focus();
				} else {
					ref.current.focus();
				}
			}
		}
	}, [visible]);

	let handleBackdropClick = e => {
		if (onDismiss != undefined) onDismiss(e);
	};

	className = `DialogContainer ${className} ${visible ? 'visible' : ''}`;

	let render = children == undefined ? previousChildren.current : children;

	let handleRest = () => {
		if (visible === false && previousChildren.current != undefined) {
			previousChildren.current = undefined;
			update();
		}
	};

	let style = useSpring({ transform: `translateY(${visible ? 0 : -24}px)`, onRest: handleRest });

	let dialogcontainer = (
		<Stylesheet>
			<div ref={ref} className={className}>
				<div id="DialogContainer_backdrop" onClick={handleBackdropClick} />
				<animated.div id="DialogContainer_animation" style={style}>
					{render}
				</animated.div>
			</div>
		</Stylesheet>
	);

	return ReactDOM.createPortal(dialogcontainer, node);
});

DialogContainer.displayName = 'DialogContainer';
DialogContainer.defaultProps = {
	className: '',
	style: {},
};

export default DialogContainer;
