import React, { forwardRef, useRef, useState, useEffect } from 'react';

function useValue(props) {
	let { value, defaultValue, onChange } = props;
	let controlled = value != undefined;
	let initialValue = controlled ? value : defaultValue;

	let previous = useRef(initialValue);
	let [stateValue, setStateValue] = useState(initialValue);

	useEffect(() => {
		previous.current = props.value;
	}, [props.value]);

	if (controlled) {
		let handleChange = (event, value) => {
			let nextvalue = value != undefined ? value : event.target.value;
			if (onChange) onChange(event, nextvalue);
		};
		return [value, handleChange];
	} else {
		let actualvalue = previous.current != undefined ? previous.current : stateValue;
		let handleChange = (event, value) => {
			let nextvalue = value != undefined ? value : event.target.value;
			setStateValue(nextvalue);
			if (onChange) onChange(event, nextvalue);
		};
		return [actualvalue, handleChange];
	}
}

export default useValue;
