import React, { useRef, useState, useEffect } from 'react';
import Style from 'react-style-sheet';
import Ripple from 'material/src/components/ripple';

const Stylesheet = Style`
	> * {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: inherit;
		position: absolute;
		pointer-events: none;
	}

`;

function useRipples(ref, props = {}) {
	let { center, disabled, onExpire, scheme, color, shade, ...other } = props;

	let id = useRef(0);
	let expired = useRef([]);
	let [ripples, setRipples] = useState([]);

	useEffect(() => {
		if (!disabled) {
			let node = ref.current;
			if (node) {
				let handleMouseDown = event => {
					if (event.target !== ref.current) return;

					let rippleID = id.current++;

					setRipples(state => {
						let rect = node.getBoundingClientRect();
						let rippling = state.filter(r => !expired.current.includes(r.id));
						let handleExpire = () => {
							expired.current.push(rippleID);
							if (onExpire) onExpire();
						};

						let newRipple = {
							id: rippleID,
							x: center != true ? event.clientX - rect.left : '50%',
							y: center != true ? event.clientY - rect.top : '50%',
							onExpire: handleExpire,
							scheme,
							color,
							shade,
							...other,
						};

						expired.current = [];
						return [...rippling, newRipple];
					});
				};
				node.addEventListener('mousedown', handleMouseDown);
				return () => {
					node.removeEventListener('mousedown', handleMouseDown);
				};
			}
		}
	}, [ref, disabled, color, center]);

	return (
		<Stylesheet>
			<svg className="Ripples">
				{ripples.map(rippleProps => {
					return <Ripple key={rippleProps.id} {...rippleProps} />;
				})}
			</svg>
		</Stylesheet>
	);
}

export default useRipples;
