import React, { useRef, forwardRef, useEffect, useContext } from 'react';
import ClassNames from 'classnames';
import Style from 'react-style-sheet';
import Icon from 'material/src/components/icon';
import Paper from 'material/src/components/paper';

import useHover from 'hooks/use-hover';
import useFocus from 'hooks/use-focus';
import useActive from 'hooks/use-active';
import useRipples from 'material/src/hooks/use-ripples';

const Stylesheet = Style`
	> Paper {
		display: flex;
		align-items: center;
		justify-content: center;

		> Icon {
			opacity: 0.6;
		}
	}

	> Paper.float {
		right: 0;
		bottom: 0;
		margin: 1.5em;
		position: absolute;
	}

	> Paper.dark {
		> Icon {
			opacity: 0.8;
		}
	}

	> Paper.focus, > Paper.hover {
		> Icon {
			opacity: 1;
		}
	}

	> Paper.disabled {
		> Icon {
			opacity: 0.38;
		}
	}	
`;

const ActionButton = forwardRef((props, outerRef) => {
	let depth, opacity;
	let { className, color, size, icon, float, disabled, style, onClick, onKeyDown, ...other } = props;

	let focus = useFocus(outerRef);
	let hover = useHover(outerRef);
	let active = useActive(outerRef);
	let ripples = useRipples(outerRef, { disabled });

	if (size == 'big') size = '56px';
	if (size == 'small') size = '40px';

	depth = disabled ? 0 : active ? 2 : 1;
	color = disabled ? '#EEEEEE' : color;
	opacity = focus || (hover && !disabled) ? 1 : undefined;

	style = Object.assign({}, style);
	style.width = style.width != undefined ? style.width : size;
	style.height = style.height != undefined ? style.height : size;

	className = `${className} ${float ? 'float' : ''}`;

	let handleKeyDown = event => {
		if (disabled) {
			event.preventDefault();
		} else if (onKeyDown) {
			onKeyDown(event);
		}
	};

	let handleClick = event => {
		if (disabled) {
			event.preventDefault();
		} else {
			if (onClick) onClick(event);
		}
	};

	className = ClassNames('ActionButton', className, float && 'float');

	return (
		<Stylesheet>
			<Paper
				ref={outerRef}
				className={className}
				style={style}
				depth={depth}
				color={color}
				shape="circle"
				element="button"
				tabIndex={0}
				onClick={handleClick}
				onKeyDown={handleKeyDown}
				{...other}
			>
				{ripples}
				<Icon id="icon" icon={icon} style={{ opacity }} />
			</Paper>
		</Stylesheet>
	);
});

ActionButton.displayName = 'ActionButton';
ActionButton.defaultProps = {
	size: 'big',
	color: 'secundary',
};

export default ActionButton;
