import React from 'react'
import Style from 'react-style-sheet'
import { Button, Paper } from 'material'

import IconSplash from '/src/assets/icon-splash.png'

const Stylesheet = Style`
    > * {
        display: grid;
        grid-template-rows: minmax(min-content, 5fr) minmax(min-content, 1fr);
        grid-template-columns: 100%;
        align-items: center;
        justify-items: center;
        padding-bottom: env(safe-area-inset-bottom);

        > div {
            margin: 20vmin;
            align-self: stretch;
            justify-self: stretch;
            background-size: contain;
            background-image: url(${IconSplash});
            background-repeat: no-repeat;
            background-position: center;
        }

        > Button {
            align-self: start;
            justify-self: center;
        }
    }
`

const Splash = props => {
	let { onStart, ...other } = props

	return (
		<Stylesheet>
			<Paper depth={0} color="blue" {...other}>
				<div />
				<Button color="grey" shade={100} label="Start" onClick={onStart} />
			</Paper>
		</Stylesheet>
	)
}

Splash.displayName = 'Splash'
Splash.defaultProps = {}

export default Splash
