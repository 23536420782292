import React, { forwardRef, useRef, useState, useEffect } from 'react';

import IconButton from './icon';
import FlatButton from './flat';
import ActionButton from './action';
import RaisedButton from './raised';
import OutlineButton from './outline';

const Button = forwardRef((props, outerRef) => {
	let { element: Element, type, submit, ...other } = props;

	if (type === 'icon') {
		Element = IconButton;
	} else if (type === 'flat') {
		Element = FlatButton;
	} else if (type === 'raised') {
		Element = RaisedButton;
	} else if (type === 'outline') {
		Element = OutlineButton;
	} else if (type === 'action') {
		Element = ActionButton;
	}

	let buttonType;
	if (submit) buttonType = 'submit';

	return <Element ref={outerRef} type={buttonType} {...other} />;
});

Button.displayName = 'Button';
Button.defaultProps = {
	type: 'raised',
	element: 'button',
};

export default Button;
