import React, { forwardRef, useRef, useContext, useEffect } from 'react';
import Style from 'react-style-sheet';
import Theme from 'material/src/utils/theme/context';

import useThemeColor from 'material/src/hooks/use-theme-color';

const Stylesheet = Style`
	> div {
        flex: 0 0 auto;
        height: 1px;
	}
`;

const Divider = forwardRef((props, outerRef) => {
	let { className, color, style, children, ...other } = props;
	let opacity;

	let ref = useRef();
	let theme = useContext(Theme);

	useEffect(() => {
		if (outerRef) outerRef.current = ref.current;
	});

	color = useThemeColor(color);
	color = color != undefined ? color : style.color;
	color = color != undefined ? color : theme.shade == 'dark' ? '#FFF' : '#000';

	opacity = style.opacity != undefined ? style.opacity : 0.1;

	let dividerStyle = { ...style, opacity, background: color };

	return (
		<Stylesheet>
			<div style={dividerStyle} {...other}>
				{children}
			</div>
		</Stylesheet>
	);
});

Divider.displayName = 'Divider';
Divider.defaultProps = {
	className: '',
	style: {},
};

export default Divider;
