import { useContext } from 'react';

import Color from 'material/src/utils/color';
import ThemeContext from 'material/src/utils/theme/context';

function useThemeColor(color) {
	let theme = useContext(ThemeContext);

	if (color == 'primary') {
		return Color(theme.primary);
	} else if (color == 'secundary') {
		return Color(theme.secundary, 'A200');
	} else {
		return color;
	}
}

export default useThemeColor;
