import React, { forwardRef, useRef, useState, useEffect, useContext } from 'react';
import Style from 'react-style-sheet';
import Label from 'material/src/components/label';
import ClassNames from 'classnames';
import BasicListitem from '../basic';

import useOuterRef from 'hooks/use-outer-ref';

const Stylesheet = Style`
    > * {
		grid-template-rows: max-content max-content;
		grid-template-areas: 
			"left label    right"
			"left sublabel right";

		> * {
			grid-row: span 2;
		}

		> Label[type=subtitle1] {
			margin: 0px 16px;
			grid-area: label;
			align-self: end;
		}

		> Label[type=subtitle] ~ Label[type=subtitle] {
			margin: 8px 16px;
			grid-area: auto / auto / span 2 / auto;
			align-self: center;
		}

		> Label[type=caption] {	
			margin: 0px 16px;
			grid-area: sublabel;
			align-self: start;
		}

		> Label[type=caption] ~ Label[type=caption] {	
			margin: 8px 16px;
			grid-area: auto / auto / span 2 / auto;
			align-self: center;
		}

		> Label {
			grid-area: right;
		}
    }
`;

const LabelListitem = forwardRef((props, outerRef) => {
	let { className, label, sublabel, children, ...other } = props;

	let ref = useOuterRef(outerRef);

	let renderLabel;
	if (label != undefined) {
		renderLabel = <Label type="subtitle1" text={label} lines={1} />;
	}

	let renderSublabel;
	if (sublabel != undefined) {
		renderSublabel = <Label type="caption" text={sublabel} lines={1} />;
	}

	let classNames = ClassNames(className, 'LabelListitem');

	return (
		<Stylesheet>
			<BasicListitem ref={ref} className={classNames} {...other}>
				{renderLabel}
				{renderSublabel}
				{children}
			</BasicListitem>
		</Stylesheet>
	);
});

LabelListitem.displayName = 'LabelListitem';
LabelListitem.defaultProps = {};

export default LabelListitem;
