import React, { forwardRef } from 'react';

import BasicList from './basic';
import ActionList from './action';

const List = forwardRef((props, outerRef) => {
	let { type, ...other } = props;

	let Element;
	if (type === 'action') {
		Element = ActionList;
	} else {
		Element = BasicList;
	}

	return <Element ref={outerRef} {...other} />;
});

List.displayName = 'List';
List.defaultProps = {};

export default List;
