import React, { useRef, forwardRef, useLayoutEffect } from 'react';
import Style from 'react-style-sheet';
import Icon from 'material/src/components/icon';
import Label from 'material/src/components/label';
import Paper from 'material/src/components/paper';

const Stylesheet = Style`
    > Paper {
		display: grid;
		flex: 0 0 auto;
		grid-auto-flow: column;
		align-items: center;
		grid-auto-columns: fit-content(56px) 1fr max-content;
		grid-template-rows: minmax(56px, max-content) max-content;
		grid-template-areas:
			"icon title controls"
			"main main  main";

		> * {
			grid-area: controls;
		}

		> Icon, > Button[type=icon] {
			margin: 8px 16px;
			grid-area: icon;
		}

		> Label {
			opacity: 1;
			margin: 8px 16px;
			grid-area: title;
		}

		> Tabs {
			grid-area: main;
		}
	}
`;

const Titlebar = forwardRef((props, outerRef) => {
	let { label, children, ...other } = props;

	let renderLabel;
	if (label != undefined) {
		renderLabel = <Label type="h6" text={label} lines={1} />;
	}

	return (
		<Stylesheet>
			<Paper ref={outerRef} color="primary" {...other}>
				{children}
				{renderLabel}
			</Paper>
		</Stylesheet>
	);
});

Titlebar.displayName = 'Titlebar';
Titlebar.defaultProps = {
	color: 'primary',
	shade: 500,
};

export default Titlebar;
