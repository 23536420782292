export default (items, fn) => {
	let result = {}
	for (let item of items) {
		let key = fn(item)
		if (result[key] == undefined) {
			result[key] = []
		}

		result[key].push(item)
	}
	return result
}
