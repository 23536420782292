import React, { useRef, useState, useEffect, useContext, forwardRef } from 'react';
import Style from 'react-style-sheet';
import Icon from 'material/src/components/icon';
import Paper from 'material/src/components/paper';
import Label from 'material/src/components/label';
import ClassNames from 'classnames';

import useOuterRef from 'hooks/use-outer-ref';
import useHover from 'hooks/use-hover';
import useFocus from 'hooks/use-focus';
import useColor from 'material/src/hooks/use-color';
import useActive from 'hooks/use-active';
import useRipples from 'material/src/hooks/use-ripples';

// When display: contents support is better refactor
// this to have a container to alter the opacity of its children
// Display: contents, allow the children of the container to be positioned against the style that was
// passed in to the component itself

const Stylesheet = Style`
	> * {
		display: inline-flex;
		flex: 0 0 auto;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		position: relative;
		padding: 5px 8px;
		min-width: 88px;
		cursor: pointer;
		text-overflow: ellipsis;
		outline: none;

		> * {
			opacity: 0.87;
			pointer-events: none;
		}

		> Icon {
			margin: 0 5px;
		}

		> Label {
			margin: 5px 8px;
			white-space: nowrap;
		}
	}

	> *.hover, > *.active, > *.focus {
		> * {
			opacity: 1;
		}
	}

	> *.disabled {
		background-color: #EEE;

		> * {
			opacity: 0.38;
		}
	}

	> *.disabled.hover, > *.disabled.focus {
		> * {
			opacity: 0.6;
		}
	}
`;

const RaisedButton = forwardRef((props, outerRef) => {
	let { className, icon, label, disabled, onClick, children, ...other } = props;

	let ref = useOuterRef(outerRef);
	let hover = useHover(ref);
	let focus = useFocus(ref);
	let active = useActive(ref);
	let ripples = useRipples(ref, { disabled });

	let depth = 1;
	if (disabled) {
		depth = 0;
	} else if (active) {
		depth = 2;
	}

	if (typeof icon === 'string') icon = <Icon icon={icon} />;
	if (typeof label === 'string') label = <Label type="button" text={label} />;

	function handleClick(event) {
		// Prevents submit buttons from submitting forms when disabled
		if (disabled) event.preventDefault();
		if (!disabled && onClick) onClick(event);
	}

	let classNames = ClassNames(className, 'RaisedButton', { hover, focus, active, disabled });

	return (
		<Stylesheet>
			<Paper
				ref={ref}
				element="button"
				className={classNames}
				depth={depth}
				shape="rounded"
				tabIndex={0}
				onClick={handleClick}
				{...other}
			>
				{ripples}
				{icon}
				{label}
				{children}
			</Paper>
		</Stylesheet>
	);
});

RaisedButton.displayName = 'RaisedButton';
RaisedButton.defaultProps = {
	color: 'primary',
};

export default RaisedButton;
